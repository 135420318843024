import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.li`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    font-size: 0.9em;
    line-height: 1.4;

    span {
        min-width: 20px;
    }

    @media all and (max-width: ${vars.mobileL}) {
        span {
            min-width: 18px;
        }
    }
`;
