const translations = {
    elements: {
        type: 'array',
        arraySchema: {
            title: { type: 'text' },
            description: { type: 'rich text' },
            buttonContenct: { type: 'text' },
            buttonStyle: { type: 'text' },
            buttonVariant: { type: 'text' },
            variant: { type: 'text' },
        },
        defaultValue: [{
            title: 'kontakt@fizjoai.pl',
            description: 'Nasi specjaliści przedstawią Ci możliwości współpracy i odpowiedzą na wszystkie pytania.',
            variant: 'secondary',
        }],
    },
};

export default translations;
