import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 100%;
	height: 100%;

	.hero-wrapper {
		min-height: 100%;

		.header {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			max-width: 30em;
			z-index: 3;

			.header-button {
				justify-content: flex-start;
				margin: 2em 0 0 0;
			}
		}

		.image-container {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 60%;
		}
	}

	@media all and (max-width: ${vars.tabletS}) {
		max-height: 100vh;

		.hero-wrapper {
			.header {
				top: 35%;
			}

			.image-container {
				width: 100%;
				z-index: 1;
			}
		}
	}
`;
