export enum HeaderStyles {
    Primary = 'primary',
    Secondary = 'secondary',
    Stats = 'stats',
    Boxes = 'boxes',
    Form = 'form',
}

export enum HeaderTextPositions {
    Center = 'center',
    Left = 'left',
}

export interface Props {
    className?: string;
    title?: string;
    subTitle?: string;
    description?: string;
    isPrimaryTitle?: boolean;
    children?: any;

    style?: HeaderStyles,
    textPosition?: HeaderTextPositions,
}
