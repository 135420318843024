import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    position: relative;
    background-color: ${vars.colorSecondaryLight};
    border-radius: ${vars.defaultRadius};
    box-shadow: ${vars.defaultRadius};
    padding: 2em;
    height: 26em;
    width: 16em;
    min-width: 10em;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    gap: 1.5em;

    .description {
        text-align: center;
        font-size: 0.9em;
        color: #666666;
        line-height: 1.4em;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .person {
        margin: auto 0 0 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1em;

        .person-avatar {
            background-color: ${vars.colorSecondaryDark};
            width: 4em;
            height: 4em;
            min-height: 4em;
            min-width: 4em;
            border-radius: 100%;
        }

        .person-details {
            height: 100%;
            display: flex;
            flex-flow: column wrap;
            align-items: flex-start;
            justify-content: center;

            .person-details-name {
                font-size: 1em;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .person-details-stars {
                margin: 0.4em 0 0 0;
            }
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
    }
`;
