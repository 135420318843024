import { FunctionComponent } from 'react';
import classNames from 'classnames';

import Box from 'components/content/blocks/Box';
import LayoutContainer from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const ContentSectionsBoxes: FunctionComponent<Props> = ({ boxes }) => {
    return (
        <StyledComponent className="content-section-boxes">
            <LayoutContainer>
                <div className="boxes-elements">
                    { Array.isArray(boxes) && boxes.map((boxProps, idx) => (
                        <Box
                            className={classNames('box-element', `box-element-${idx+1}`)}
                            key={boxProps.title}
                            {...boxProps}
                        />
                    ))}
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default ContentSectionsBoxes;