import { FunctionComponent, useMemo } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';

import { mergeTranslations } from 'utils/translations';

import { Props } from './types';

const LayoutSectionsWrapper: FunctionComponent<Props> = ({ sections, memoParams, localTranslations }) => {
    const parsedSections = useMemo(() => {
        const sortedSections = sections.sort((a, b) => a.order - b.order);

        return sortedSections
            .filter(sortedSection => sortedSection.isVisible)
            .map((sortedSection, index) => {
                const localSectionTranslations = localTranslations[sortedSection.slug] || {};
                const translations = mergeTranslations(null, localSectionTranslations);

                if (index > 2) {
                    const ComponentDelayedHydration = withHydrationOnDemand({ on: ['visible'] })(() => sortedSection.children(sortedSection.slug, translations));
                    return (<ComponentDelayedHydration key={sortedSection.slug} />);
                }

                return sortedSection.children(sortedSection.slug, translations);
            });
    }, memoParams);

    return (<>{parsedSections}</>);
};

export default LayoutSectionsWrapper;
