import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 100%;
	height: 100vh;
	max-height: 100vh;
	overflow: hidden;
	background-color: ${vars.colorPrimaryBlue};

	.header-subtitle {
		color: #EEEEEE !important;
	}

	@media all and (max-width: ${vars.mobileL}) {
	}
`;
