import { FunctionComponent } from 'react';

import Header from 'components/layout/Header';
import { HeaderStyles } from 'components/layout/Header/types';
import IconCircle from 'components/layout/IconCricle';

import StyledComponent from './styles';
import { Props } from './types';

const ContentBlocksIconWithDescription: FunctionComponent<Props> = ({ title, description, icon }) => {
    return (
        <StyledComponent className="content-blocks-icon-with-details">
            <IconCircle
                className="icon-circle"
                iconSrc={icon}
                iconAlt="Ikona cudzysłowu"
            />
            <Header
                title={title}
                description={description}
                style={HeaderStyles.Stats}
            />
        </StyledComponent>
    );
};

export default ContentBlocksIconWithDescription;