import { FunctionComponent } from 'react';

import StyledComponent from './styles';
import { Props } from './types';

const ContentSectionsTiles: FunctionComponent<Props> = ({ title, subTitle }) => {
    return (
        <StyledComponent className="content-customs-tile">
            <p className="sub-title">{subTitle}</p>
            <p className="title">{title}</p>
        </StyledComponent>
    );
};

export default ContentSectionsTiles;