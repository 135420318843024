import { FunctionComponent } from 'react';

import Button from 'components/layout/Button';
import { ButtonStyles } from 'components/layout/Button/types';
import Header from 'components/layout/Header';
import { HeaderTextPositions } from 'components/layout/Header/types';
import LayoutContainer from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const ContentSectionsContactBox: FunctionComponent<Props> = ({ title, description, buttonContent, butonHref  }) => {
    return (
        <StyledComponent className="content-section-contact-box">
            <LayoutContainer className="contact-box">
                <Header
                    className='header'
                    title={title}
                    description={description}
                    textPosition={HeaderTextPositions.Left}
                />
                <a href={butonHref}>
                    <Button
                        className="cta-button"
                        style={ButtonStyles.Secondary}
                        ariaLabel="Przejdź do sekcji kontaktowej"
                    >
                        {buttonContent}
                    </Button>
                </a>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default ContentSectionsContactBox;