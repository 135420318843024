import { FunctionComponent } from 'react';

import ImagesWithDetails from 'components/content/sections/ImagesWithDetails';

import StyledComponent from './styles';
import { Props } from './types';

const PageHomeSectionAboutUs: FunctionComponent<Props> = ({ translations }) => {
    return (
        <StyledComponent
            className="page-home-section-about-us"
            id="about"
        >
            <ImagesWithDetails elements={translations?.elements} />
        </StyledComponent>
    );
};

export default PageHomeSectionAboutUs;