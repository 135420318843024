import { FunctionComponent } from 'react';
import classnames from 'classnames';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutListContainer: FunctionComponent<Props> = ({ className, children }) => ((
    <StyledComponent className={classnames('layout-list-container', className)}>
        {children}
    </StyledComponent>
));

export default LayoutListContainer;