import { FunctionComponent } from 'react';

import TilesSection from 'components/content/sections/serviceTiles';
import Header from 'components/layout/Header';
import { HeaderStyles, HeaderTextPositions } from 'components/layout/Header/types';
import LayoutContainer from 'components/layout/LayoutContainer/component';

import StyledComponent from './styles';
import { Props } from './types';

const PageHomeSectionExploreServices: FunctionComponent<Props> = ({ translations }) => {
    return (
        <StyledComponent
            className="page-home-section-explore-services"
            id="services"
        >
            <LayoutContainer className="explore-services">
                <Header
                    className="explore-services-header"
                    title={translations?.title}
                    subTitle={translations?.subTitle}
                    description={translations?.description}
                    style={HeaderStyles.Secondary}
                    textPosition={HeaderTextPositions.Center}
                />
                <TilesSection
                    tiles={translations?.elements}
                />
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PageHomeSectionExploreServices;
