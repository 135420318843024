const translations = {
    title: {
        type: 'text',
        defaultValue: 'Co sprawia, że oprogramowanie FizjoAI jest wyjątkowe?',
    },
    subTitle: {
        type: 'text',
        defaultValue: 'Dlaczego FizjoAI?',
    },
    description: {
        type: 'rich text',
        defaultValue: 'Naszym celem jest zapewnienie Ci skutecznego i wygodnego narzędzia do fizjoterapii, które pomoże Ci w powrocie do pełnej sprawności fizycznej.',
    },
    elements: {
        type: 'array',
        arraySchema: {
            title: { type: 'text' },
            icon: { type: 'image' },
        },
        defaultValue: [{
            title: 'Biblioteka ćwiczeń',
            icon: '/icons/doctor.svg',
            iconAlt: 'Ikona biblioteki ćwiczeń',
        }, {
            title: 'Precyzja',
            icon: '/icons/results.svg',
            iconAlt: 'Ikona precyzji',
        }, {
            title: 'Elastyczna pozycja kamery',
            icon: '/icons/legs.svg',
            iconAlt: 'Ikona elastycznej pozycji kamery',
        }],
    },
};

export default translations;
