import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    width: 100%;

    .images-with-details {
        display: flex;
        flex-flow: column wrap;
        gap: 6em;
    }
`;
