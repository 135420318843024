import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	margin: 0 auto;

	.boxes-elements {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		display: flex;
		justify-content: space-between;
		gap: 2em;
		width: 100%;
	}

	@media all and (max-width: ${vars.mobileL}) {
		.boxes-elements {
			justify-content: center;

			.box-element {
				display: none;
			}
			.box-element-1 {
				display: flex;
			}
		}
	}
`;
