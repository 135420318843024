import { FunctionComponent } from 'react';
import classNames from 'classnames';

import Button from 'components/layout/Button';
import Header from 'components/layout/Header';
import { HeaderStyles, HeaderTextPositions } from 'components/layout/Header/types';

import StyledComponent from './styles';
import { Props } from './types';

const ContentSectionsContactBox: FunctionComponent<Props> = ({ className, title, description, buttonContent, buttonStyle, buttonVariant, variant }) => {
    return (
        <StyledComponent className={classNames('content-section-box', className)}>
            <div className={classNames('box', `variant-${variant}`)}>
                <Header
                    className='header'
                    title={title}
                    description={description}
                    textPosition={HeaderTextPositions.Left}
                    style={HeaderStyles.Boxes}
                >
                    <Button
                        className="cta-button"
                        style={buttonStyle}
                        variant={buttonVariant}
                        ariaLabel="Przejdź do sekcji kontaktowej"
                    >
                        {buttonContent}
                    </Button>
                </Header>
            </div>
        </StyledComponent>
    );
};

export default ContentSectionsContactBox;