import { LocalFieldConfig } from 'models/PageSection';

export function mergeTranslations(apiTranslations: Record<string, any>, localTranslations: Record<string, LocalFieldConfig>): Record<string, any> {
    const result = {};
    Object.keys(localTranslations).forEach((fieldKey: string) => {
        if(typeof apiTranslations?.[fieldKey] !== 'undefined') {
            result[fieldKey] = apiTranslations[fieldKey];
            return;
        }

        result[fieldKey] = localTranslations[fieldKey].defaultValue;
        return;
    });

    return result;
}

export const mapTranslationsByName = (names: string[] = [], translations: Record<string, any>): any => {
    if (!names) return;

    return names.reduce((prevValue, name) => ({
        ...prevValue,
        [name]: translations?.[name],
    }), {});
};