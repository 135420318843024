import { FunctionComponent } from 'react';

import IconWithDetails from 'components/content/blocks/IconWithDetails';
import LayoutContainer from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const ContentSectionsStatsBox: FunctionComponent<Props> = ({ stats }) => (
    <StyledComponent className="content-section-stats-box">
        <LayoutContainer className="stats-box">
            {Array.isArray(stats) && stats.map(({ title, description, icon }) => (
                <IconWithDetails
                    key={title}
                    title={title}
                    description={description}
                    icon={icon}
                />
            ))}
        </LayoutContainer>
    </StyledComponent>
);

export default ContentSectionsStatsBox;