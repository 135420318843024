import { GetStaticProps, GetStaticPropsContext, GetStaticPropsResult } from 'next';

import Component from './component';
import { Props } from './types';

export const getStaticProps: GetStaticProps = async (context: GetStaticPropsContext): Promise<GetStaticPropsResult<Props>> => {
    return {
        props: {
            localTranslations: require('locales/pl/pages/home')?.default,
            metaTranslations: require('locales/pl/pages/home')?.meta,
        },
        revalidate: 10,
    };
};

export default Component;
