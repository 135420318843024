import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    position: relative;
    width: 100%;
    height: 100%;
    margin: 4em 0;

    .tiles-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        justify-content: center;

        .content-blocks-tile,
        .content-customs-tile {
            flex: 1 1 270px;
        }
    }

    @media all and (max-width: ${vars.desktopXS}) {
        .tiles-container {
            .content-blocks-tile,
            .content-customs-tile {
                flex: 1 1 300px;
                max-width: 300px;
            }
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
        .tiles-container {
            .content-blocks-tile,
            .content-customs-tile {
                flex: 1 1 100%;
            }
        }
    }
`;
