const translations = {
    title: {
        type: 'text',
        defaultValue: 'FizjoAI - Twój osobisty fizjoterapeuta',
    },
    subTitle: {
        type: 'text',
        defaultValue: 'Analiza ruchu człowieka nr 1 na rynku',
    },
    description: {
        type: 'richText',
        defaultValue: 'Nasza innowacyjna platforma łączy najnowsze osiągnięcia w dziedzinie sztucznej inteligencji z wiedzą z zakresu fizjoterapii, aby dostarczać spersonalizowane i skuteczne rozwiązania dla Twoich potrzeb rehabilitacyjnych.',
    },
    button: {
        type: 'text',
        defaultValue: 'Kontakt',
    },
};

export default translations;
