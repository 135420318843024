import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 100%;
	min-height: 30em;
	overflow: hidden;
	background-color: ${vars.colorPrimaryLight};
	padding: 4em 0 8em 0;

	@media all and (max-width: ${vars.mobileL}) {
	}
`;
