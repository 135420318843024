import { FunctionComponent } from 'react';

import TileBlock from 'components/content/blocks/serviceTile';
import CustomTile from 'components/content/custom/customTile';

import StyledComponent from './styles';
import { Props } from './types';;

const ContentSectionsTiles: FunctionComponent<Props> = ({ tiles, customTile }) => {
    return (
        <StyledComponent className="content-sections-titles">
            <div className="tiles-container">
                { Array.isArray(tiles) && tiles?.map((tile, idx) => (
                    <TileBlock
                        key={idx}
                        {...tile}
                    />
                ))}
                {customTile && (
                    <CustomTile {...customTile} />
                )}
            </div>
        </StyledComponent>
    );
};

export default ContentSectionsTiles;
