import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6em;
    height: 100%;

    .image-col {
        position: relative;
        min-height: 30em;
        min-width: 47%;

        .image {
            border-radius: ${vars.defaultRadius};
            box-shadow: ${vars.defaultShadow};
        }
    }

    .details-col {
        position: relative;

        .button-cta {
            justify-content: flex-start;
            margin: 3em 0;
        }
    }

    &.image-position-left {
        .image-col {
            order: 1;
        }

        .details-col {
            order: 2;
        }
    }

    &.image-position-right {
        .image-col {
            order: 2;
        }

        .details-col {
            order: 1;
        }
    }

    @media all and (max-width: ${vars.tabletL}) {
        flex-flow: column wrap;
        max-width: 80%;
        margin: 0 auto;

        .image-col {
            width: 100%;
        }

        &.image-position-left {
            .image-col {
                order: 2;
            }

            .details-col {
                order: 1;
            }
        }

        &.image-position-right {
            .image-col {
                order: 2;
            }

            .details-col {
                order: 1;
            }
        }
    }
`;
