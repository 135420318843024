import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;

	.stats-box {
		border-radius: ${vars.defaultRadius};
		background-color: ${vars.colorSecondaryLight};
		min-height: 16em;
		padding: 4em;
		display: flex;
		align-items: center;
		justify-content: space-around;
		gap: 2em;
	}

	@media all and (max-width: ${vars.mobileL}) {
		.stats-box {
			flex-flow: column wrap;
			gap: 4em;
			max-width: 80%;
		}
	}
`;
