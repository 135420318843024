import { FunctionComponent } from 'react';
import classnames from 'classnames';

import Button from 'components/layout/Button';
import Header from 'components/layout/Header';
import { HeaderStyles, HeaderTextPositions } from 'components/layout/Header/types';
import Image from 'components/layout/Image';
import ListContainer from 'components/layout/ListContainer';
import ListElement from 'components/layout/ListElement';

import StyledComponent from './styles';
import { Props } from './types';

const ContentBlocksImageWithDescription: FunctionComponent<Props> = ({
    title,
    description,
    image,
    imagAlt,
    list,
    buttonContent,
    imagePosition,
    subListDescription,
}) => {
    return (
        <StyledComponent className={classnames('content-blocks-image-with-details', `image-position-${imagePosition}`)}>
            <div className="image-col">
                <Image
                    src={image}
                    alt={imagAlt || 'Prezentacja wykorzystania FizjoAI'}
                    className="image"
                    objectFit="cover"
                    layout="fill"
                />
            </div>
            <div className="details-col">
                <Header
                    title={title}
                    description={description}
                    style={HeaderStyles.Secondary}
                    textPosition={HeaderTextPositions.Left}
                />
                {Array.isArray(list) && (
                    <ListContainer>
                        {list.map((item, idx) => (
                            <ListElement
                                key={idx}
                                text={item}
                            />
                        ))}
                    </ListContainer>
                )}
                {subListDescription && (
                    <div className="sub-list-descirpton-row">
                        {subListDescription}
                    </div>
                )}
                {buttonContent && (
                    <Button className="button-cta">
                        {buttonContent}
                    </Button>
                )}
            </div>
        </StyledComponent>
    );
};

export default ContentBlocksImageWithDescription;
