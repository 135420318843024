import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 100%;
	min-height: 30em;
	overflow: hidden;
	background: linear-gradient(180deg, ${vars.colorPrimaryBlue} 50%, ${vars.colorPrimaryLight} 50%);

	@media all and (max-width: ${vars.mobileL}) {
		overflow: unset;
	}
`;
