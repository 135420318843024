import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 100%;
	height: 100%;
	margin: 4em 0;

	.tiles-container {
		display: flex;
		gap: 1em;
		justify-content: center;

		.content-blocks-tile,
		.content-customs-tile {
			width: 25%;
		}
	}

	@media all and (max-width: ${vars.mobileL}) {
		.tiles-container {
			flex-flow: column;

			.content-blocks-tile,
			.content-customs-tile {
				width: 80%;
				margin: 0 auto;
			}
		}
	}
`;
