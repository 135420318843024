import { FunctionComponent } from 'react';

import QuoteIcon from 'theme/icons/quote.svg';

import Image from 'components/layout/Image';
import Stars from 'components/layout/Stars';

import StyledComponent from './styles';
import { Props } from './types';

const ContentBlocksClientTile: FunctionComponent<Props> = ({ name, description, stars }) => {
    return (
        <StyledComponent className="content-blocks-tile">
            <Image
                src={QuoteIcon}
                layout='fixed'
                alt="Ikona cudzysłowu"
            />
            <p className="description">{description}</p>
            <div className="person">
                <div className='person-details'>
                    <p className="person-details-name">{name}</p>
                    <div className="person-details-stars">
                        <Stars starsCount={stars} />
                    </div>
                </div>
            </div>
        </StyledComponent>
    );
};

export default ContentBlocksClientTile;
