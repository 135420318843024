import metadata from './meta/metadata';
import aboutUs from './sections/aboutUs';
import clients from './sections/clients';
import contact from './sections/contact';
import exploreService from './sections/exploreService';
import footerBoxes from './sections/footerBoxes';
import hero from './sections/hero';
import stats from './sections/stats';

const translations = {
    hero,
    exploreService,
    contact,
    clients,
    stats,
    aboutUs,
    footerBoxes,
};

export const meta = {
    ...metadata,
};

export default translations;