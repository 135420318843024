import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    .contact-box {
        border-radius: ${vars.defaultRadius};
        background: url('/images/contact/background.svg') no-repeat right bottom ${vars.colorPrimaryCTA};
        min-height: 16em;
        padding: 4em;
        display: flex;
        justify-content: space-between;

        .header {
            max-width: 50%;
            margin: 0 0 0 2em;

            .header-title {
                font-size: 2.5em;
            }
        }

        .cta-button {
            max-width: 50%;
            margin: 0 2em 0 0;
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
        .contact-box {
            flex-flow: column;

            .header {
                margin: 2em 0;
                max-width: 100%;
            }

            .cta-button {
                margin: 0;
                justify-content: flex-start;
            }
        }
    }
`;
