import { FunctionComponent } from 'react';

import StatsBox from 'components/content/sections/statsBox';

import StyledComponent from './styles';
import { Props } from './types';

const PageHomeSectionStats: FunctionComponent<Props> = ({ translations }) => {
    return (
        <StyledComponent className="page-home-section-stats">
            <StatsBox
                stats={translations?.elements}
            />
        </StyledComponent>
    );
};

export default PageHomeSectionStats;