import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.header`
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;

    .header-subtitle {
        font-family: ${vars.fontSecondary};
        color: #666666;
        letter-spacing: 2px;
        font-size: 1.1em;
        text-transform: uppercase;
    }

    .header-title {
        font-family: ${vars.fontSecondary};
        color: ${vars.colorTextWhite};
        line-height: 1.2em;
        font-size: 3.8em;
        margin: 0.2em 0;
    }

    .header-description {
        font-size: 1.1em;
        color: ${vars.colorTextWhite};
        line-height: 1.5em;
    }

    &.style-primary {
        .header-subTitle {
            color: ${vars.colorTextBlueLighter};
        }

        .header-title {
            color: ${vars.colorTextWhite};
        }

        .header-description {
            color: ${vars.colorTextWhite};
        }
    }

    &.style-secondary {
        .header-subTitle {
            color: ${vars.colorTextDark};
        }

        .header-title {
            color: ${vars.colorTextDark};
        }

        .header-description {
            color: ${vars.colorTextDark};
        }
    }

    &.style-stats {
        justify-content: center;

        .header-subTitle {
            color: ${vars.colorTextDark};
        }

        .header-title {
            color: ${vars.colorTextDark};
            font-size: 2.4em;
            font-weight: 700;
            margin: 0;
        }

        .header-description {
            color: ${vars.colorTextLight};
            line-height: unset;
        }
    }

    &.style-boxes {
        .header-subTitle {
            color: ${vars.colorTextBlueLighter};
        }

        .header-title {
            color: ${vars.colorTextWhite};
        }

        .header-description {
            color: ${vars.colorSecondaryDark};
        }
    }

    &.style-form {
        .header-subTitle {
            color: ${vars.colorPrimaryDark};
        }

        .header-title {
            color: ${vars.colorPrimaryDark};
            margin: 0;
        }

        .header-description {
            color: ${vars.colorTextDarkLighter};
        }
    }

    &.text-left {
        align-items: flex-start;
    }

    &.text-center {
        align-items: center;
        text-align: center;
    }

    @media all and (max-width: ${vars.mobileL}) {
        .header-title {
            font-size: 3em;
        }

        .header-description {
            font-size: 1.1em;
        }
    }

    @media all and (max-width: ${vars.mobileS}) {
        .header-title {
            font-size: 2.6em;
        }

        .header-description {
            font-size: 1.1em;
        }
    }
`;
