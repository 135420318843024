import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 50%;

	.box {
		border-radius: ${vars.defaultRadius};
		background-color: ${vars.colorPrimaryCTA};
		min-height: 16em;
		padding: 4em;
		display: flex;
		justify-content: space-between;
		height: 20em;

		&.variant-primary {
			background: url('/images/boxes/map.svg') no-repeat right bottom
				${vars.colorPrimaryCTA};
		}

		&.variant-secondary {
			background: url('/images/boxes/doctor.svg') no-repeat right bottom
				${vars.colorSecondaryBlue};
		}

		.header {
			margin: 0 0 0 2em;

			.header-title {
				font-size: 2.5em;
			}

			.cta-button {
				margin: auto 0 0 0;
			}
		}
	}

	@media all and (max-width: ${vars.mobileL}) {
		width: 100%;

		.box {
			padding: 2em;
			background-size: 100%;
			width: 100%;

			.header {
				margin: 0;
				padding: 0em;
				flex-flow: column;
			}

			&.variant-primary {
				background-size: 100%;
			}
			&.variant-secondary {
				background-size: 50%;
			}
		}
	}
`;
