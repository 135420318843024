import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	position: relative;
	background-color: ${vars.colorPrimaryLight};
	border-radius: 100%;
	padding: 1em;
	width: 5em;
	height: 5em;
	display: flex;
	place-content: center;
	overflow: hidden;
	
	.icon {
		object-fit: cover;
		padding: 0.01em;
	}
`;
