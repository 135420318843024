import { FunctionComponent } from 'react';

import ImageWithDetails from 'components/content/blocks/ImageWithDetails';
import LayoutContainer from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const ContentSectionsImagesWithDetails: FunctionComponent<Props> = ({ elements }) => (
    <StyledComponent className="content-section-images-with-details">
        <LayoutContainer className="images-with-details">
            {Array.isArray(elements) && elements.map((element) => (
                <ImageWithDetails
                    key={element?.title}
                    {...element}
                />
            ))}
        </LayoutContainer>
    </StyledComponent>
);

export default ContentSectionsImagesWithDetails;
