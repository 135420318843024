import { FunctionComponent } from 'react';
import classnames from 'classnames';

import CheckIcon from 'theme/icons/check.svg';

import Image from 'components/layout/Image';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutListElement: FunctionComponent<Props> = ({ text, className }) => ((
    <StyledComponent className={classnames('layout-list-element', className)}>
        <Image
            className="list-element-icon"
            src={CheckIcon}
            objectFit='contain'
            layout='fixed'
            alt="Ikona elementu listy"
        />
        <div className="list-element-text">
            {text}
        </div>
    </StyledComponent>
));

export default LayoutListElement;
