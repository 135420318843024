const translations = {
    elements: {
        type: 'array',
        arraySchema: {
            title: { type: 'text' },
            description: { type: 'rich text' },
            image: { type: 'image' },
            imageAlt: { type: 'text' },
            imagePosition: { type: 'text' },
            list: {
                type: 'array',
                arraySchema: {
                    point: { type: 'text' },
                },
            },
            subListDescription: { type: 'text' },
            buttonContent: {
                type: 'text',
            },
        },
        defaultValue: [{
            title: 'Możliwości są nieskończone',
            description: 'Aplikacja FizjoAI oferuje szereg różnych możliwości, które pomagają użytkownikom w procesie rehabilitacji i poprawie stanu zdrowia. Oto niektóre z nich:',
            image: '/images/about-us/example-one.webp',
            imageAlt: 'Przykład wykorzystania FizjoAI w domu',
            imagePosition: 'left',
            list: [
                'Spersonalizowany program rehabilitacyjny: FizjoAI analizuje dane użytkownika i generuje spersonalizowany program rehabilitacyjny, uwzględniający indywidualne potrzeby, historię urazów i cele terapeutyczne.',
                'Wizualne instrukcje i wsparcie multimedialne: Aplikacja dostarcza wizualne instrukcje, wideo czy animacje, które pomagają użytkownikom poprawnie wykonywać ćwiczenia i techniki terapeutyczne.',
                'Monitorowanie postępów: FizjoAI umożliwia użytkownikom śledzenie swojego postępu rehabilitacyjnego w czasie rzeczywistym, co pozwala na ocenę skuteczności programu i dostosowanie go do bieżących potrzeb.',
                'Adaptacyjność programu: Na podstawie danych o postępach i reakcjach użytkownika, FizjoAI dostosowuje program rehabilitacyjny, zapewniając spersonalizowane podejście i optymalne rezultaty',
                'Konsultacje online z fizjoterapeutą: Aplikacja umożliwia użytkownikom skonsultowanie się z fizjoterapeutą online, co pozwala na uzyskanie profesjonalnej porady, odpowiedzi na pytania i wsparcia w czasie rzeczywistym.',
                'Szeroki zakres technik terapeutycznych: FizjoAI oferuje różnorodne techniki rehabilitacyjne, takie jak ćwiczenia, masaż, stretching, mobilizacje czy stabilizacje, które są dostosowane do konkretnych potrzeb i stanu użytkownika.',
                'Dostępność i wygoda: Aplikacja jest dostępna na różnych urządzeniach, takich jak smartfony, tablety czy komputery, co pozwala użytkownikom na wygodne korzystanie z programu rehabilitacyjnego w dowolnym miejscu i czasie.',
            ],
        }, {
            title: 'Rozwiązania zdrowotne',
            description: 'Aplikacja FizjoAI oferuje szereg rozwiązań zdrowotnych, które wspierają użytkowników w poprawie i utrzymaniu zdrowia fizycznego. Oto niektóre z nich:',
            image: '/images/about-us/example-two.webp',
            imageAlt: 'Przykład wykorzystania FizjoAI poza domem',
            imagePosition: 'right',
            list: [
                'Indywidualny program rehabilitacyjny: FizjoAI analizuje dane pacjenta i tworzy spersonalizowany program rehabilitacyjny, dostosowany do konkretnych potrzeb i celów terapeutycznych. To pozwala użytkownikom skupić się na obszarach wymagających poprawy i osiągać optymalne wyniki.',
                'Wsparcie w codziennej aktywności fizycznej: Aplikacja FizjoAI dostarcza użytkownikom różnorodne ćwiczenia i techniki terapeutyczne, które można wykonywać samodzielnie w domu. To pomaga w utrzymaniu aktywnego stylu życia i zapobieganiu występowaniu kontuzji czy przeciążeń.',
                'Poprawa siły i elastyczności: Dzięki programom treningowym i technikom terapeutycznym, FizjoAI pomaga użytkownikom zwiększyć siłę mięśniową, poprawić elastyczność i wytrzymałość. To jest istotne zarówno w kontekście rehabilitacji, jak i ogólnego zdrowia i kondycji fizycznej.',
                'Zarządzanie bólem i ograniczeniami ruchowymi: Aplikacja FizjoAI oferuje techniki terapeutyczne, które pomagają w łagodzeniu bólu, redukcji obrzęków oraz zwiększaniu zakresu ruchu. To szczególnie przydatne dla osób z przewlekłymi dolegliwościami, urazami czy stanami zapalnymi.',
                'Profesjonalna konsultacja online: FizjoAI umożliwia pacjentom skonsultowanie się z fizjoterapeutą online, dzięki czemu mogą uzyskać fachowe porady i wsparcie w czasie rzeczywistym. To pomaga w monitorowaniu postępów, rozwiązywaniu problemów oraz dostosowywaniu programu rehabilitacyjnego.',
                'Edukacja i świadomość zdrowotna: Aplikacja FizjoAI dostarcza użytkownikom wiedzy na temat różnych aspektów zdrowia fizycznego i fizjoterapii. To pomaga w zrozumieniu własnego stanu zdrowia, włączeniu się w proces rehabilitacji oraz podejmowaniu świadomych decyzji dotyczących zdrowego stylu życia.',
            ],
            subListDescription: 'Dzięki tym rozwiązaniom zdrowotnym, aplikacja FizjoAI wspiera użytkowników w procesie rehabilitacji, utrzymaniu zdrowia fizycznego i osiąganiu optymalnej kondycji. To kompleksowe podejście do fizjoterapii, które umożliwia samodzielną pracę nad zdrowiem i poprawą sprawności fizycznej.',
        }],
    },
};

export default translations;
