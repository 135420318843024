const translations = {
    elements: {
        type: 'array',
        arraySchema: {
            title: { type: 'text' },
            description: { type: 'text' },
            icon: { type: 'image' },
        },
        defaultValue: [{
            title: '31',
            description: 'ćwiczeń',
            icon: '/icons/calendar.svg',
        }, {
            title: '3k',
            description: 'wykonanych treningów',
            icon: '/icons/question.svg',
        }, {
            title: '105',
            description: 'zadowolonych klientów',
            icon: '/icons/cough.svg',
        }],
    },
};

export default translations;
