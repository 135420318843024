import { FunctionComponent } from 'react';

import IconCircle from 'components/layout/IconCricle/component';

import StyledComponent from './styles';
import { Props } from './types';

const ContentBlocksTile: FunctionComponent<Props> = ({ title, icon, iconAlt }) => {
    return (
        <StyledComponent className="content-blocks-tile">
            <div
                className="icon"
                aria-hidden="true"
            >
                <IconCircle
                    iconSrc={icon}
                    iconAlt={iconAlt}
                />
            </div>
            <p className="title">{title}</p>
        </StyledComponent>
    );
};

export default ContentBlocksTile;