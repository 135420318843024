import { useMemo } from 'react';
import { NextPage } from 'next';

import { PageSection } from 'types/pages';

import Head from 'components/layout/Head';
import PageContainer from 'components/layout/PageContainer';
import SectionsWrapper from 'components/layout/SectionsWrapper';

import SectionAboutUs from './sections/AboutUs';
import SectionClients from './sections/Clients';
import SectionContact from './sections/Contact';
import SectionExploreService from './sections/ExploreService';
import SectionFooterBoxes from './sections/FooterBoxes';
import SectionHero from './sections/Hero';
import SectionStats from './sections/Stats';
import StyledComponent from './styles';
import { Props } from './types';

const PageHome: NextPage<Props> = ({ localTranslations, metaTranslations }) => {
    const sections: PageSection[] = useMemo(() => [{
        slug: 'hero',
        order: 1,
        isVisible: true,
        children: (slug, translations) => (
            <SectionHero
                key={slug}
                translations={translations}
            />
        ),
    }, {
        slug: 'exploreService',
        order: 2,
        isVisible: true,
        children: (slug, translations) => (
            <SectionExploreService
                key={slug}
                translations={translations}
            />
        ),
    }, {
        slug: 'contact',
        order: 3,
        isVisible: true,
        children: (slug, translations) => (
            <SectionContact
                key={slug}
                translations={translations}
            />
        ),
    }, {
        slug: 'clients',
        order: 4,
        isVisible: true,
        children: (slug, translations) => (
            <SectionClients
                key={slug}
                translations={translations}
            />
        ),
    }, {
        slug: 'stats',
        order: 5,
        isVisible: false,
        children: (slug, translations) => (
            <SectionStats
                key={slug}
                translations={translations}
            />
        ),
    }, {
        slug: 'aboutUs',
        order: 6,
        isVisible: true,
        children: (slug, translations) => (
            <SectionAboutUs
                key={slug}
                translations={translations}
            />
        ),
    }, {
        slug: 'footerBoxes',
        order: 7,
        isVisible: true,
        children: (slug, translations) => (
            <SectionFooterBoxes
                key={slug}
                translations={translations}
            />
        ),
    }], []);

    return (
        <StyledComponent className="page-home">
            <Head
                {...metaTranslations}
            />
            <PageContainer>
                <SectionsWrapper
                    sections={sections}
                    localTranslations={localTranslations}
                />
            </PageContainer>
        </StyledComponent>
    );
};

export default PageHome;
