import { FunctionComponent } from 'react';

import ContactBox from 'components/content/sections/contactBox';

import StyledComponent from './styles';
import { Props } from './types';

const PageHomeSectionContact: FunctionComponent<Props> = ({ translations }) => {
    return (
        <StyledComponent
            className="page-home-section-contact"
        >
            <ContactBox
                title={translations?.title}
                description={translations?.description}
                buttonContent={translations?.buttonContent}
                butonHref="/#contact"
            />
        </StyledComponent>
    );
};

export default PageHomeSectionContact;