import { ButtonStyles, ButtonVariants } from 'components/layout/Button/types';

export enum BoxVariants {
    Primary = 'primary',
    Secondary = 'secondary',
}

export interface Props {
    title: string;
    description: string;
    buttonContent: string;
    buttonStyle: ButtonStyles;
    buttonVariant: ButtonVariants,
    variant: BoxVariants;
    className?: string;
}
