import { FunctionComponent } from 'react';

import StarIcon from 'theme/icons/star.svg';

import Image from 'components/layout/Image';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutStars: FunctionComponent<Props> = ({ starsCount }) => {
    const starsArray = Array.from(Array(starsCount).keys());

    return (
        <StyledComponent className="layout-stars">
            { starsArray.map((value) => (
                <Image
                    key={value}
                    className="star-icon"
                    src={StarIcon}
                    layout='fixed'
                    objectFit='scale-down'
                    width={20}
                    height={20}
                    alt="Ikona oceny"
                />
            ))}
        </StyledComponent>
    );
};

export default LayoutStars;