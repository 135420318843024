import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 100%;
	min-height: 30em;
	overflow: hidden;
	background: linear-gradient(180deg, ${vars.colorPrimaryLight} 50%, ${vars.colorPrimaryDark} 50%);

	.boxes-elements {
		width: 100%;

		.content-section-box {
			width: 100%;

			.box {
				width: 100%;
				background-color: #4a57a7;

				.header-description {
					color: white;
				}

				.layout-button {
					display: none;
				}
			}
		}
	}

	@media all and (max-width: ${vars.mobileL}) {
	}
`;
