import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2em;

	.icon-circle {
		margin: 0 0 0.2em 0;
		min-width: 5em;
		min-height: 5em;
	}

	@media all and (max-width: ${vars.mobileL}) {
	}
`;
