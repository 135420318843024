import { FunctionComponent } from 'react';
import getConfig from 'next/config';
import Head from 'next/head';

import { Envs } from 'types/envs';

import { Props } from './index';

const { publicRuntimeConfig } = getConfig();

const LayoutHead: FunctionComponent<Props> = ({ title, titleSuffix, children, description, canonical, image, robots }) => {
    if (publicRuntimeConfig.APP_ENV !== Envs.Production) {
        robots = 'noindex,nofollow';
    }

    return (
        <div>
            <Head>
                {/* Seo */}
                <title>{title} {titleSuffix}</title>
                {description && (
                    <meta
                        name="description"
                        content={description}
                    />
                )}
                {canonical && (
                    <link
                        rel='canonical'
                        href={publicRuntimeConfig.APP_URL + canonical}
                    />
                )}
                {robots && (
                    <meta
                        name="robots"
                        content={robots}
                    />
                )}

                {/* openGraph */}
                <meta
                    property="og:image"
                    content={image || publicRuntimeConfig.APP_URL + '/images/og-default.jpg'}
                />
                <meta
                    property="og:title"
                    content={title}
                />
                {description && (
                    <meta
                        property="og:description"
                        content={description}
                    />
                )}
            </Head>

            {children}
        </div>
    );
};

export default LayoutHead;