import { FunctionComponent } from 'react';

import PersonImage from 'theme/images/hero/person.svg';

import Button from 'components/layout/Button';
import Header from 'components/layout/Header';
import Image from 'components/layout/Image';
import LayoutContainer from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const ContentSectionsHero: FunctionComponent<Props> = ({ title, subTitle, description, buttonContent, buttonHref }) => {
    return (
        <StyledComponent className="content-section-hero">
            <LayoutContainer className="hero-wrapper">
                <Header
                    className='header'
                    title={title}
                    subTitle={subTitle}
                    description={description}
                    isPrimaryTitle={true}
                >
                    <a href={buttonHref}>
                        <Button
                            className="header-button"
                            ariaLabel="Przejdź do sekcji kontaktowej"
                        >
                            {buttonContent}
                        </Button>
                    </a>
                </Header>
                <div className="image-container">
                    <Image
                        width="100%"
                        height="100%"
                        layout="responsive"
                        className='image'
                        src={PersonImage}
                        alt="Osoba prezentująca ból kręgosłupa, któremu pomóc moe FizjoAI"
                    />
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default ContentSectionsHero;