import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 100%;
	min-height: 30em;
	overflow: hidden;
	background-color: ${vars.colorPrimaryBlue};

	.header-subtitle {
		color: #EEEEEE !important;
	}

	@media all and (max-width: ${vars.mobileL}) {
		.clients {
			max-width: 80%;
			margin: 0 auto;
		}
	}
`;
