export enum ImagePositions {
    Left = 'left',
    Right = 'right',
}

export interface Props {
    title: string;
    description: string;
    image: string;
    imagAlt: string;
    list?: string[];
    buttonContent?: string;
    imagePosition?: ImagePositions;
    subListDescription?: string;
}
