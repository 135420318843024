import { FunctionComponent } from 'react';

import ClientTileBlock from  'components/content/blocks/clientTile';

import StyledComponent from './styles';
import { Props } from './types';

const ContentSectionsTiles: FunctionComponent<Props> = ({ tiles }) => {
    return (
        <StyledComponent className="content-sections-contact-titles">
            <div className="tiles-container">
                { Array.isArray(tiles) && tiles?.map((tile, idx) => (
                    <ClientTileBlock
                        key={idx}
                        {...tile}
                    />
                ))}
            </div>
        </StyledComponent>
    );
};

export default ContentSectionsTiles;