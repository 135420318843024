import { FunctionComponent } from 'react';

import BoxesSection from 'components/content/sections/boxes';

import StyledComponent from './styles';
import { Props } from './types';

const PageHomeSectionFooterBoxes: FunctionComponent<Props> = ({ translations }) => {
    return (
        <StyledComponent
            className="page-home-section-footer-boxes"
            id="contact"
        >
            <BoxesSection
                boxes={translations?.elements}
            />
        </StyledComponent>
    );
};

export default PageHomeSectionFooterBoxes;