import Component from './component';
import { HeaderStyles, HeaderTextPositions } from './types';

Component.defaultProps = {
    title: null,
    className: null,
    subTitle: null,
    description: null,
    isPrimaryTitle: false,
    style: HeaderStyles.Primary,
    textPosition: HeaderTextPositions.Left,
    children: null,
};
export default Component;