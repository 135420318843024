import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
	position: relative;
	width: 100%;
	min-height: 30em;
	overflow: hidden;
	background-color: ${vars.colorPrimaryLight};

	.explore-services {
		margin: 8em auto 0 auto;
	}

	@media all and (max-width: ${vars.mobileL}) {

		.explore-services {
			.explore-services-header {
				width: 80%;
				margin: 0 auto;
			}
		}
	}
`;
