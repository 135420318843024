const translations = {
    title: {
        type: 'text',
        defaultValue: 'Masz pytania?',
    },
    description: {
        type: 'text',
        defaultValue: 'Skontaktuj się znami. Twój feedback i pytania są dla nas bardzo ważne. Postaramy się odpowiedzieć na Twoją wiadomość najszybciej, jak to możliwe.',
    },
    buttonContent: {
        type: 'text',
        defaultValue: 'Kontakt',
    },
};

export default translations;
