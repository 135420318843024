const translations = {
    title: {
        type: 'text',
        defaultValue: 'Opinie naszych klientów',
    },
    subTitle: {
        type: 'text',
        defaultValue: 'Mówią o nas',
    },
    description: {
        type: 'rich text',
        defaultValue: 'Zachęcamy do zapoznania się z opiniami naszych klientów na temat rozwiązania FizjoAI',
    },
    elements: {
        type: 'array',
        arraySchema: {
            description: { type: 'rich text' },
            name: { type: 'text' },
            stars: { type: 'number' },
        },
        defaultValue: [{
            description: '"Jestem zachwycony aplikacją FizjoAI! Dzięki niej mogę regularnie wykonywać ćwiczenia rehabilitacyjne w domu. Program jest spersonalizowany do moich potrzeb, a wizualne instrukcje pomagają mi poprawnie wykonywać ćwiczenia. Moje dolegliwości się poprawiły, a ja czuję się silniejszy i bardziej elastyczny. Polecam wszystkim, którzy potrzebują profesjonalnej fizjoterapii w wygodny sposób!"',
            name: 'Jacek',
            stars: 5,
        }, {
            description: '"FizjoAI to dla mnie znalezisko! Już od dawna miałam problemy z bólem pleców i próbowałam różnych metod leczenia. Ta aplikacja okazała się najskuteczniejsza! Dostosowany program rehabilitacyjny i wsparcie multimedialne sprawiają, że mogę wykonywać ćwiczenia w domu bez obaw o złe wykonanie. Moje plecy coraz mniej bolą, a moja postawa i siła się poprawiają. Naprawdę warto spróbować!"',
            name: 'Katarzyna',
            stars: 5,
        }, {
            description: '"FizjoAI to mój codzienny towarzysz w procesie rehabilitacji. Jestem pod wrażeniem elastyczności programu, który dostosowuje się do moich postępów i potrzeb. Dodatkowo, możliwość skonsultowania się z fizjoterapeutą online jest niesamowicie pomocne. Dzięki aplikacji czuję się bardziej zaangażowana we własną terapię i widzę znaczące rezultaty. Polecam wszystkim, którzy chcą aktywnie pracować nad swoim zdrowiem!"',
            name: 'Marta',
            stars: 5,
        }, {
            description: '"FizjoAI to rewelacyjne narzędzie dla mnie jako zawodowego sportowca. Moja rehabilitacja nigdy nie była tak spersonalizowana i wygodna. Programy treningowe i techniki terapeutyczne w aplikacji są skuteczne i łatwe do wykonania. Dodatkowo, śledzenie mojego postępu i możliwość dostosowania programu to ogromny plus. Dzięki FizjoAI mogę szybko wrócić do pełnej formy po kontuzji!"',
            name: 'Piotr',
            stars: 5,
        }],
    },
};

export default translations;
