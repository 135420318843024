import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	position: relative;
	background-color: ${vars.colorSecondaryLight};
	border-radius: ${vars.defaultRadius};
	box-shadow: ${vars.defaultRadius};
	padding: 2em;
	height: 17em;
	width: 16em;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	

	.title {
		font-size: 1.2em;
	}

	@media all and (max-width: ${vars.mobileL}) {
	}
`;
